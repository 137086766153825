import {SelectItem} from 'primeng/api';
import {sortByLabel} from '../helpers/helperFunctions';

interface BrandOrTypeSelectItem extends SelectItem<string> {
  'hardwareName'?: string;
  'brandOrTypeRegex'?: string;
  'manufacturedYearRegex'?: string;
  'yearFirst'?: boolean;
  'weekOrMonth'?: string;
  'manualFile'?: string;
}

const HARDWARE_LOOKUP_KEY_PREFIX: string = 'hardware: brands/types for plan code ';

let hardwareSerialLookups: BrandOrTypeSelectItem[];
let brandsOrTypesByPlanCode: {[planCode: string]: BrandOrTypeSelectItem[]} = {};

function getHardwareSerialLookups(): BrandOrTypeSelectItem[] {
  if (!hardwareSerialLookups) {
    const tmpHardwareSerialLookups: BrandOrTypeSelectItem[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key: string = localStorage.key(i);
      if (key.startsWith(HARDWARE_LOOKUP_KEY_PREFIX)) {
        const brandSelects: BrandOrTypeSelectItem[] = JSON.parse(localStorage.getItem(key))
        brandSelects.forEach((brandOrType: BrandOrTypeSelectItem) => {
          if (brandOrType.brandOrTypeRegex) {
            tmpHardwareSerialLookups.push(brandOrType);
          }
        });
      }
    }
    if (tmpHardwareSerialLookups.length > 0) {
      hardwareSerialLookups = tmpHardwareSerialLookups;
    }
    return tmpHardwareSerialLookups;
  }
  return hardwareSerialLookups;
}

function getBrandsOrTypesForPlanCode(planCode: string): BrandOrTypeSelectItem[] {
  if (!brandsOrTypesByPlanCode[planCode]) {
    const lookupKey: string = `${HARDWARE_LOOKUP_KEY_PREFIX}${planCode}`;
    // Handle requests to get details before login is complete
    if (!localStorage.getItem(lookupKey)) {
      return [];
    }
    brandsOrTypesByPlanCode[planCode] = JSON.parse(localStorage.getItem(lookupKey)) || [];
  }
  return brandsOrTypesByPlanCode[planCode];
}

let equipmentLookups: BrandOrTypeSelectItem[] = [];
// We cannot distinguish between a Pebbell and Chiptech where a phone number entered
const GPS_PLANS: string[] = [
  '#1B', '#1BF', '#1T', '#1TF', '#1J', '#1JF'
];

const UK_PHONE_NUM_MATCH: RegExp = /\b447\d{9}\b/;

function getBrandOrTypeFromSerialAndPlan(serial: string, planCode: string): BrandOrTypeSelectItem {
  let matchingSelectItem: BrandOrTypeSelectItem = undefined;
  if (!serial) {
    return matchingSelectItem;
  }
  if (GPS_PLANS.includes(planCode)) {
    const brandOrTypeLookups: BrandOrTypeSelectItem[] = getBrandsOrTypesForPlanCode(planCode);
    matchingSelectItem = brandOrTypeLookups.find((brandOrType: BrandOrTypeSelectItem) => {
      if (brandOrType.brandOrTypeRegex) {
        const typeRegex: RegExp = new RegExp(brandOrType.brandOrTypeRegex, 'i');
        return typeRegex.test(serial);
      }
      return false;
    });
  } else {
    if (equipmentLookups.length == 0) {
      let tmpEquipmentLookups: BrandOrTypeSelectItem[] = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key: string = localStorage.key(i);
        const keyPlanCode: string = key.replace(HARDWARE_LOOKUP_KEY_PREFIX, '');
        if (key.startsWith(HARDWARE_LOOKUP_KEY_PREFIX) && !GPS_PLANS.includes(keyPlanCode)) {
          let brandSelects: BrandOrTypeSelectItem[] = JSON.parse(localStorage.getItem(key));
          if (brandSelects && brandSelects.length > 0) {
            brandSelects = brandSelects.filter((brandOrType: BrandOrTypeSelectItem) => !!brandOrType.brandOrTypeRegex);
            if (brandSelects.length > 0) {
              tmpEquipmentLookups = tmpEquipmentLookups.concat(brandSelects);
            }
          }
        }
      }
      if (tmpEquipmentLookups.length > 0) {
        equipmentLookups = tmpEquipmentLookups;
      }
    }
    matchingSelectItem = equipmentLookups.find((brandOrType: BrandOrTypeSelectItem) => {
      const typeRegex: RegExp = new RegExp(brandOrType.brandOrTypeRegex, 'i');
      return typeRegex.test(serial);
    });
  }
  return matchingSelectItem;
}

function getManualFilenameFromSerialAndPlan(serial: string, planCode: string): string {
  const matchingSelectItem: BrandOrTypeSelectItem = getBrandOrTypeFromSerialAndPlan(serial, planCode);
  if (matchingSelectItem && matchingSelectItem.manualFile) {
    return matchingSelectItem.manualFile;
  }
  return '';
}

function getManualLookups(): SelectItem<string>[] {
  const manualLookups: SelectItem<string>[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key: string = localStorage.key(i);
    if (key.startsWith(HARDWARE_LOOKUP_KEY_PREFIX)) {
      const brandSelects: BrandOrTypeSelectItem[] = JSON.parse(localStorage.getItem(key))
      brandSelects.forEach((brandOrType: BrandOrTypeSelectItem) => {
        if (brandOrType.manualFile) {
          const manualAlreadyInList: boolean = manualLookups.some((lookup: SelectItem<string>) => {
            return (lookup.value == brandOrType.manualFile);
          });
          if (!manualAlreadyInList) {
            manualLookups.push({
              'label': brandOrType.label,
              'value': brandOrType.manualFile
            });
          }
        }
      });
    }
  }
  return sortByLabel(manualLookups);
}

function getEquipmentNameFromSerialAndPlan(serial: string, planCode: string): string {
  const matchingSelectItem: BrandOrTypeSelectItem = getBrandOrTypeFromSerialAndPlan(serial, planCode);
  if (matchingSelectItem && matchingSelectItem.label) {
    if (GPS_PLANS.includes(planCode) && UK_PHONE_NUM_MATCH.test(serial)) {
      return `${matchingSelectItem.label} - GPS brand assumed`;
    }
    return matchingSelectItem.label;
  }
  return '';
}

function getEquipmentNameFromSerial(serial: string): string {
  if (!serial) {
    return '';
  }
  if (UK_PHONE_NUM_MATCH.test(serial)) {
    return 'Pebbell/GO - Unknown';
  }
  const serialLookups: BrandOrTypeSelectItem[] = getHardwareSerialLookups();
  const matchingSelectItem: BrandOrTypeSelectItem = serialLookups.find((serialLookup: BrandOrTypeSelectItem) => {
    const serialRegex: RegExp = new RegExp(serialLookup.brandOrTypeRegex, 'i');
    return (serialRegex.test(serial));
  });
  if (matchingSelectItem) {
    return matchingSelectItem.label;
  }
  return '';
}


function getManualFilenameFromSerial(serial: string): string {
  if (!serial) {
    return '';
  }
  const serialLookups: BrandOrTypeSelectItem[] = getHardwareSerialLookups();
  const upperSerial: string = serial.toLocaleUpperCase();
  const matchingSelectItem: BrandOrTypeSelectItem = serialLookups.find((serialLookup: BrandOrTypeSelectItem) => {
    const serialRegex: RegExp = new RegExp(serialLookup.brandOrTypeRegex, 'i');
    return (serialRegex.test(serial));
  });
  if (matchingSelectItem) {
    return matchingSelectItem.manualFile;
  }
  return '';
}

export {
  BrandOrTypeSelectItem,
  getBrandsOrTypesForPlanCode,
  getManualFilenameFromSerialAndPlan,
  getManualFilenameFromSerial,
  getManualLookups,
  getEquipmentNameFromSerialAndPlan,
  getEquipmentNameFromSerial,
}
