import {ImportType} from '../models/importType.model';

// TODO remove for Hardware version
export const importTypes: ImportType[] = [
  {
    'appName': 'add-tag2order',
    'label': 'Add Tag',
    'purpose': 'Add a single Tag to the order if it does not already exist, without overwriting existing tags.',
    'orderCreation': 'No',
    'requiredColumns': '"Brand", "Td Code" and "Tag"',
    'notes': 'The Tag column must contain the name of a tag that exists in the CRM and the spacing and punctuation must match. ' +
      'The import will run in the background and email you the results on completion.',
  },
  {
    'appName': 'add-note2order',
    'label': 'Add Note',
    'purpose': 'Add a single Note to the order, with the supplied categories.',
    'orderCreation': 'No',
    'requiredColumns': '"Brand", "Td Code", "Note" and "Note Categories"',
    'notes': 'The Note Categories column must contain one or more categories, separated by | or , as shown on the CRM record page. ' +
      'The import will run in the background and email you the results on completion.',
  },
  {
    'appName': 'bc-monthly-invoices',
    'label': 'Create Business Central Website Invoices',
    'purpose': 'Create the Business Central invoice files from the alarm site website data for new orders and replacements. ' +
      'The uploaded excel spreadsheet must contain sheets called "CK", "CL", "LL", "LLIE" and "TC" containing the order exports ' +
      'for that brand for the month being processed.',
    'orderCreation': 'No, does not affect CRM orders.',
    'requiredColumns': 
      'For each brand\'s sheet "Order ID", "Order Date", "Order Status", "Order Currency", "Order Total", "Customer Note", ' +
      '"Billing First Name", "Billing Last Name", "Customer Account Email Address", "Product ID", "Product Name", "Quantity", ' +
      '"Item Cost", "Item Tax" (or "Item Tax Amount"), "Coupon Code" and "order_type".',
    'notes': 'The import will run in the background and email you the results on completion.',
  },
  {
    'appName': 'bc-post-sheet',
    'label': 'Create Business Central Postsheet Invoices',
    'purpose': 'Create the Business Central invoice files from the post sheet data. ' +
      'The uploaded excel spreadsheet must contain a sheet called "Post Archive" containing ONLY the records for the month being ' +
      'processed (based on the "Date" column) from the sheet of the same name in "Post and Info Packs ARCHIVE" google sheet.',
    'orderCreation': 'No, does not affect CRM orders.',
    'requiredColumns': 
      'For the "Post Archive" sheet "Brand", "Date", "TD Code", "What to Send Main product", "Payment Ex VAT" and' +
      '"VAT YES/NO".',
    'notes': 'The import will run in the background and email you the results on completion.',
  },
  {
    'appName': 'create-order',
    'label': 'Create Order',
    'purpose': 'Create a new order - this is a stripped down version of the import supporting only the fields used for Bulk account/referral imports. ' +
      'In particular it only allows up to one account contact, note, VIM and one plan.',
    'orderCreation': 'Yes, always. Updates not allowed.',
    'requiredColumns': 
      '"Brand", "Td Code", "Status", "Customer Name", "VIM", "Telephone", "Email", "Mobile", "Address Line 1", "Address Line 2", "City", "County", "Postcode", ' +
        '"Account Contact First Name", "Account Contact Last Name", "Account Contact Email", "Account Contact Telephone", "Account Contact Mobile"',
    'notes': 'The import will run in the background and email you the results on completion.',
  },
  {
    "appName": "create review",
    "label": "Create Review",
    "purpose": "To create the order review, if it is already not there",
    "orderCreation": "No",
    "requiredColumns": "'name', 'rating', 'review', 'date', 'order_id'",
    "notes": 'A review is considered new and is therefore imported if there is not already a review for that order and date and time.'
  },
  {
    'appName': 'update-renewal-price',
    'label': 'Update Renewal Price',
    'purpose': 'To update the renewal price on the order, if it is not already the provided value.',
    'orderCreation': 'No',
    'requiredColumns': '"Brand", "Td Code", "Old Renewal Price", "New Renewal Price" and "Reason"',
    'notes': 'The New Renewal Price must be a valid number, with no currency indicator. ' +
      'The Old Renewal Price must match the current renewal price, if there is currently a renewal price (no currency indicator). ' +
      'This is a safety mechanism to stop the change if the values do not match in case other changes make this update invalid. ' +
      'The reason will be used in a note added to the order with details of the price change ' +
      '(in the same format as when you change the price through the CRM record page). ' +
      'The import will run in the background and email you the results on completion.',
  },
  {
    'appName': 'update-renewal-type',
    'label': 'Update Renewal Type',
    'purpose': 'To update the renewal type on the order, if it is not already the provided value.',
    'orderCreation': 'No',
    'requiredColumns': '"Brand", "Td Code" and "Renewal Type"',
    'notes': 'Be sure to check the results for orders which need the old payment method stopping. ' +
      'The import will run in the background and email you the results on completion.',
  },
];

// TODO add for Hardware version
/* 
export const importTypes: ImportType[] = [
  {
    'appName': 'add-tag2order',
    'label': 'Add Tag',
    'purpose': 'Add a single Tag to the order if it does not already exist, without overwriting existing tags.',
    'orderCreation': 'No',
    'requiredColumns': '"Brand", "Td Code" and "Tag"',
    'notes': 'The Tag column must contain the name of a tag that exists in the CRM and the spacing and punctuation ' +
      'must match. The import will run in the background and email you the results on completion.',
  },
  {
    'appName': 'add-note2order',
    'label': 'Add Note',
    'purpose': 'Add a single Note to the order, with the supplied categories.',
    'orderCreation': 'No',
    'requiredColumns': '"Brand", "Td Code", "Note" and "Note Categories"',
    'notes': 'The Note Categories column must contain one or more categories, separated by | or , as ' +
      'shown on the CRM record page. The import will run in the background and email you the results on completion.',
  },
  {
    'appName': 'create-order',
    'label': 'Create Order',
    'purpose': 'Create a new order - this is a stripped down version of the import supporting only the fields used ' +
      'for Bulk account/referral imports. In particular it only allows up to one account contact, note and VIM.  ' +
      'It does allow multiple hardware sets and additional hardware (matching by name) with their serials.',
    'orderCreation': 'Yes, always. Updates not allowed.',
    'requiredColumns': '"Brand", "Td Code", "Status", "Customer Name", "VIM", "Telephone", "Email", "Mobile", ' +
      '"Address Line 1", "Address Line 2", "City", "County", "Postcode", "Account Contact First Name", ' +
      '"Account Contact Last Name", "Account Contact Email", "Account Contact Telephone", "Account Contact Mobile"',
    'notes': 'The import will run in the background and email you the results on completion.',
  },
  {
    'appName': 'update-frozen-price',
    'label': 'Update Renewal Price',
    'purpose': 'To update the Frozen price on the order, before discounts, if it is not already the provided value.',
    'orderCreation': 'No',
    'requiredColumns': '"Brand", "Td Code" and "Frozen Price"',
    'notes': 'The Frozen Price must be a valid number, with no currency indicator. The order must have a ' +
      '"Price Frozen" tag on it for the update to be allowed - other renewal prices are calculated automatically, ' + 
      'so no longer allow updates. BE AWARE: any discounts on the order are applied to this price, so will reduce what the customer pays.' + 
      'The import will run in the background and email you the results on completion.',
  },
  {
    'appName': 'update-renewal-type',
    'label': 'Update Renewal Type',
    'purpose': 'To update the renewal type on the order, if it is not already the provided value.',
    'orderCreation': 'No',
    'requiredColumns': '"Brand", "Td Code" and "Renewal Type"',
    'notes': 'Be sure to check the results for orders which need the old payment method stopping. ' +
      'The import will run in the background and email you the results on completion.',
  },
  {
    "appName": "create review",
    "label": "Create Review",
    "purpose": "To create the order review, if it is already not there",
    "orderCreation": "No",
    "requiredColumns": "'name', 'rating', 'review', 'date', 'order_id'",
    "notes": 'A review is considered new, so imported, if there is not already a review for that order, date and time.'
  },
];
*/