import { Pipe, PipeTransform } from '@angular/core';
import {getEquipmentNameFromSerial, getEquipmentNameFromSerialAndPlan} from '../lookups/hardwareBrandsOrTypes';

@Pipe({
  name: 'serialToName'
})
export class SerialToNamePipe implements PipeTransform {

  transform(serial: string, planCode?: string): string {
    const nameUsingPlanCode: string = getEquipmentNameFromSerialAndPlan(serial, planCode);
    if (nameUsingPlanCode) {
      return nameUsingPlanCode;
    }
    return getEquipmentNameFromSerial(serial);
  }

}
