import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
const EMAIL_VALIDATION_REGEX: RegExp = /^[^@\s;]+@[a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)+$/;
const INTERNAL_DOMAINS: string[] = [
  'lifeline24.co.uk', 'careline.co.uk', 'carelink24.org', 'lifeline24.ie', 'telecarechoice.co.uk',
  'lifeconnect24.co.uk', 'lifeconnect24.ie', 'careline365.co.uk', 'appello.co.uk'
];

export function isValidExternalEmailAddress(emailAddress: string): boolean {
  if (!EMAIL_VALIDATION_REGEX.test(emailAddress)) {
    return false;
  }
  const emailDomain: string = emailAddress.split('@').pop();
  if (INTERNAL_DOMAINS.includes(emailDomain.toLocaleLowerCase())) {
    return false;
  }
  return true;
}

export function isValidEmailAddress(emailAddress: string): boolean {
  return EMAIL_VALIDATION_REGEX.test(emailAddress);
}

export const emailValidator: ValidatorFn = (emailField: AbstractControl): ValidationErrors|null => {
  if (!EMAIL_VALIDATION_REGEX.test(emailField.value)) {
    return {'emailAddressValid': emailField.value};
  }
  return null;
}

export function getExternalEmailValidator(emailRequired: boolean): ValidatorFn {
  return (emailField: AbstractControl): ValidationErrors|null => {
    if (!emailRequired && !emailField.value) {
      return null;
    }
    if (!isValidExternalEmailAddress(emailField.value)) {
      return {'emailAddressValid': emailField.value};
    }
    return null;
  }
}

export function getInvalidOrInternalEmailsFromMultiEmailString(multiEmailString: string): string[] {
  const invalidEmails: string[] = [];
  if (!multiEmailString) {
    return invalidEmails;
  }
  const emailAddresses: string[] = multiEmailString.split(/[;\s\n]+/);
  emailAddresses.forEach((emailAddress: string): void => {
    emailAddress = emailAddress.trim();
    if (emailAddress.length == 0) {
      return;
    }
    if (!isValidExternalEmailAddress(emailAddress)) {
      invalidEmails.push(emailAddress);
    }
  });
  return invalidEmails;
}

export const multiExternalEmailValidator: ValidatorFn = (multiEmailField: AbstractControl): ValidationErrors|null => {
  if (!multiEmailField.value) {
    return null;
  }
  const invalidEmails: string[] = getInvalidOrInternalEmailsFromMultiEmailString(multiEmailField.value as string);
  if (invalidEmails.length > 0) {
    return {'emailAddressValid': invalidEmails.join(';')};
  }
  return null;
}

export function getInvalidEmailsFromMultiEmailString(multiEmailString: string): string[] {
  const invalidEmails: string[] = [];
  if (!multiEmailString) {
    return invalidEmails;
  }
  const emailAddresses: string[] = multiEmailString.split(/[;\s\n]+/);
  emailAddresses.forEach((emailAddress: string): void => {
    emailAddress = emailAddress.trim();
    if (emailAddress.length == 0) {
      return;
    }
    if (!isValidEmailAddress(emailAddress)) {
      invalidEmails.push(emailAddress);
    }
  });
  return invalidEmails;
}

export const multiEmailValidator: ValidatorFn = (multiEmailField: AbstractControl): ValidationErrors|null => {
  if (!multiEmailField.value) {
    return null;
  }
  const invalidEmails: string[] = getInvalidEmailsFromMultiEmailString(multiEmailField.value as string);
  if (invalidEmails.length > 0) {
    return {'emailAddressValid': invalidEmails.join(';')};
  }
  return null;
}