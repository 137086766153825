import {SelectItem} from 'primeng/api';

const LEGAL_COMPANIES: SelectItem<string>[] = [
  { label: 'Careline365 Ltd', value: 'Careline365 Ltd' },
  { label: 'Lifeline24 Ltd', value: 'Lifeline24 Ltd' },
];

export {
  LEGAL_COMPANIES,
};
