<div class="row mx-0 my-2 messageDetails">
  <div class="col-6 col-lg-2 offset-0 offset-lg-1">
    <div>
      Order Status:
      <span class="statusBlock" [style.background-color]="getStatusBackgroundColour(order.status.status)" 
          [style.color]="getStatusColour(order.status.status)">
        {{order.status.status}}
      </span>
    </div>
  </div>
  <div class="col-6 col-lg-3">
    Order Date: {{order.created | date:'dd/MM/y'}}
  </div>
  <div class="col-6 col-lg-2">
    Plan: {{order.accountDetails.plan}}
  </div>
  <div class="col-6 col-lg-4">
    Renewal Type: {{renewalTypeDisplayLookup[order.renewalInformation.renewalType]}}
  </div>
</div>
<div class="row mx-0 my-2 messageDetails">
  <div class="col-6 col-lg-2 offset-0 offset-lg-1">
    Renewal Price: {{order.renewalInformation.renewalPrice}}
  </div>
  <div class="col-6 col-lg-3">
    Payment Due: {{order.renewalInformation.paymentDueDate | date:'dd/MM/y'}}
    <br *ngIf="!isSmallerThanLarge" />
    {{message.daysToGo}} days to go
  </div>
  <div class="col-6 col-lg-3">
    Expiry Date On Order: {{order.renewalInformation.renewalDate | date:'dd/MM/y'}}
  </div>
  <div class="col-6 col-lg-3">
    Expiry Date When Message Created: {{message.renewalDate | date:'dd/MM/y'}}
  </div>
</div>
<form [formGroup]="messageForm">
  <div class="row mx-0 my-2 messageDetails" formGroupName="renewal">
    <div class="col-6 col-lg-5 offset-0 offset-lg-1">
      <label for="renewalLink">Renewal Link</label>
      <input type="text" class="form-control validated" name="renewalLink" id="renewalLink" formControlName="renewalLink" />
    </div>
    <div class="col-6 col-lg-6">
      <div class="mb-3">Show Offer</div>
      <label for="showDdOffer" class="mr-2">Annual Direct Debit Discount:</label>
      <input type="checkbox" name="showDdOffer" id="showDdOffer" formControlName="showDdOffer" />
    </div>
  </div>
</form>