import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {OverlayOptions, SelectItem} from 'primeng/api';
import {Address, getFormattedAddress} from '../../helpers/getUserAddress';
import {getMessageConfig} from '../../lookups/manualSendMessageTypes';
import {ProposedMessageService} from '../../messages-list/proposed-message.service';
import {CancellationInfo} from '../../models/cancellationInfo.model';
import {ContactDetails} from '../../models/contactDetails.model';
import {Order} from '../../models/order.model';
import {OutstandingAction} from '../../models/outstandingAction.model';
import {ProposedMessage} from '../../models/proposedMessage.model';
import {SingleRecordResponse} from '../../models/responses/singleRecordResponse.model';
import {unstructuredAddressValidator} from '../../validators/unstructured-address.validator';
import getAddressClient, {FindFailed, FindSuccess, Result} from 'getaddress-api';
import {getLookupFromGetAddressResult} from '../../helpers/getAddressHelper';
import {environment} from '../../../environments/environment';
import {DropDownChangeEvent} from '../../models/primeng/dropdownChangeEvent.model';
import {getContactFlags} from '../../helpers/helperFunctions';
import {OrderFormTag} from '../../models/orderFormTag.model';
import {PRICE_REGEX} from '../../helpers/getPlanCodeAndPrice';
import {DEFAULT_OVERLAY} from '../../lookups/primeng/overlayOptions';

@Component({
  selector: 'app-letter-send[order][tags][letterTypes][closeModal]',
  templateUrl: './letter-send.component.html',
  styleUrls: ['./letter-send.component.scss']
})
export class LetterSendComponent implements OnInit {
  @Input() order: Order; // Tags on definition don't match actual format, so have to send in separately
  @Input() tags: OrderFormTag[];
  @Input() letterTypes: SelectItem<string>[];
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  availableContactDetails: SelectItem<ContactDetails>[] = [];
  contactDetailsToPickFrom: SelectItem<ContactDetails>[] = [];
  contactForm: UntypedFormGroup;
  sendDisabled: boolean;
  selectedContactDetails: ContactDetails;
  resultMessage: string;
  resultSuccess: boolean;
  dialogVisible: boolean;
  cancellationData: CancellationInfo;
  getAddrClient: getAddressClient;
  searchPostCode: string;
  addressSearchError: string;
  addressResults: SelectItem<Address>[];
  allowAddressManualEntry: boolean;
  hasBlockAlarmComms: boolean;
  overlayOptions: OverlayOptions = DEFAULT_OVERLAY;

  constructor(
    private proposedMessageService: ProposedMessageService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.sendDisabled = true;
    this.dialogVisible = true;
    this.allowAddressManualEntry = false;
    this.getAddrClient = new getAddressClient(environment.getAddressDomainToken);
    this.prepareContacts();
    this.prepareCancellationLetters();
    this.hasBlockAlarmComms = this.tags.some((tag: OrderFormTag) => 
      tag.content == 'Block Alarm Correspondence'
    );
    
    this.contactForm = this.formBuilder.group({
      'letterTypeToSend': [null, Validators.required],
      'source': ['Manually Entered'],
      'firstName': [null, Validators.required],
      'lastName': [null, Validators.required],
      'reFirstName': [null],
      'reLastName': [null],
      'address': [null, unstructuredAddressValidator],
      'letterVariation': [null, Validators.required],
      'unitCost': [null, Validators.required],
      'vatCost': [null, Validators.required],
      'equipCost': [null, Validators.required],
      'equipName': [null, Validators.required],
    });
    // Select the first type automatically
    if (this.letterTypes.length > 0) {
      this.contactForm.controls['letterTypeToSend'].setValue(this.letterTypes[0].value);
    }
    this.selectedTypeChange();
    if (this.contactDetailsToPickFrom.length > 1) {
      // Select the first contact on loading to save staff time if they want to use the default (most likely)
      this.selectedContactDetails = this.contactDetailsToPickFrom[0].value;
      this.selectedContactChange();
    }
    this.sendDisabled = false;
  }

  prepareContacts(): void {
    // We don't need mobile or email for sending letters
    if (this.order.alarmUserDetails.correspondenceDetails?.firstName || this.order.alarmUserDetails.correspondenceDetails?.lastName) {
      this.availableContactDetails.push({
        label: `Correspondent: ${this.order.alarmUserDetails.correspondenceDetails.firstName}` +
          `${this.order.alarmUserDetails.correspondenceDetails.lastName}; ${this.order.alarmUserDetails.correspondenceDetails.addressOne}`,
        value: {
          'source': 'Correspondent',
          'firstName': this.order.alarmUserDetails.correspondenceDetails.firstName,
          'lastName': this.order.alarmUserDetails.correspondenceDetails.lastName,
          'email': '',
          'mobile': '',
          'address': getFormattedAddress(this.order.alarmUserDetails.correspondenceDetails).split('\n'),
        }
      });
      // Don't allow user only types if there's a correspondent
      this.letterTypes = this.letterTypes.filter((letterType: SelectItem<string>) => 
        !getMessageConfig(letterType.value).toUsersOnly
      );
    } else {
      // Don't allow letters to alarm users if correspondent filled in
      this.availableContactDetails.push({
        label: `Alarm User: ${this.order.alarmUserDetails.firstName} ${this.order.alarmUserDetails.lastName}; ` +
          `${this.order.alarmUserDetails.userAddress.addressOne} ${getContactFlags(this.order.alarmUserDetails)}`,
        value: {
          'source': 'Alarm User',
          'firstName': this.order.alarmUserDetails.firstName,
          'lastName': this.order.alarmUserDetails.lastName,
          'email': '',
          'mobile': '',
          'address': getFormattedAddress(this.order.alarmUserDetails.userAddress).split('\n'),
        }
      });
      let userNumber: number = 1;
      this.order.alarmUserDetails.users.forEach(user => {
        this.availableContactDetails.push({
          label: `Additional User ${userNumber++}: ${user.firstName} ${user.lastName}; ${this.order.alarmUserDetails.userAddress.addressOne} ${getContactFlags(user)}`,
          value: {
            'source': `Additional User ${userNumber++}`,
            'firstName': user.firstName,
            'lastName': user.lastName,
            'email': '',
            'mobile': '',
            'address': getFormattedAddress(this.order.alarmUserDetails.userAddress).split('\n'),
          }
        });
      });
    }
    let contactNumber: number = 1;
    this.order.accountContacts.forEach(accountContact => {
      this.availableContactDetails.push({
        label: `Account Contact ${contactNumber++}: ${accountContact.accFirstName} ${accountContact.accLastName} ${getContactFlags(accountContact)}`,
        value: {
          'source': `Account Contact ${contactNumber++}`,
          'firstName': accountContact.accFirstName,
          'lastName': accountContact.accLastName,
          'email': '',
          'mobile': '',
          'address': [],
        }
      });
    });
    if ((this.order.status.status == 'cancelling') && this.order.cancellation && this.order.cancellation.personReturning) {
      this.availableContactDetails.push({
        label: `Cancellation Contact: ${this.order.cancellation.personReturning}`,
        value: {
          'source': 'Cancellation Contact',
          'firstName': this.order.cancellation.personReturning,
          'lastName': '',
          'email': '',
          'mobile': '',
          'address': [],
        }
      });
    }
    const returningEquipAction: OutstandingAction = this.order.outstandingActions.find((action: OutstandingAction) => 
      action.outstandingName == 'Returning Equipment'
    );
    if (returningEquipAction && returningEquipAction.personReturning) {
      this.availableContactDetails.push({
        label: `Returning Equip Contact: ${returningEquipAction.personReturning}`,
        value: {
          'source': 'Returning Equip Contact',
          'firstName': returningEquipAction.personReturning,
          'lastName': '',
          'email': '',
          'mobile': '',
          'address': [],
        }
      });
    }
  }

  prepareCancellationLetters(): void {
    if (this.order.status.status != 'cancelling') {
      this.letterTypes = this.letterTypes.filter((letterType: SelectItem<string>) => 
        !letterType.value.startsWith('Cancellation')
      );
    } else {
      if (this.letterTypes.some((letterType: SelectItem<string>) =>
        letterType.value.startsWith('Cancellation')
      )) {
        this.proposedMessageService.getCancellationData(this.order._id)
          .subscribe((cancelInfoResult: SingleRecordResponse<CancellationInfo>) => {
            if (cancelInfoResult.success && cancelInfoResult.data) {
              this.cancellationData = cancelInfoResult.data;
            }
          });
      }
    }
  }

  get areRegardingFieldsRequired(): boolean {
    return (getMessageConfig(this.contactForm.controls['letterTypeToSend'].value)?.regardingFields == 'required');
  }

  get areRegardingFieldsAllowed(): boolean {
    return ['optional', 'required'].includes(getMessageConfig(this.contactForm.controls['letterTypeToSend'].value)?.regardingFields);
  }

  get hasRegardingFirstName(): boolean {
    return !!this.reFirstName.value;
  }

  get hasRegardingLastName(): boolean {
    return !!this.reLastName.value;
  }

  get toUsersOnly(): boolean {
    return getMessageConfig(this.contactForm.controls['letterTypeToSend'].value)?.toUsersOnly;
  }

  get needsPaymentDetails(): boolean {
    const letterType: string = (this.contactForm.controls['letterTypeToSend'].value as string);
    if (!letterType) {
      return false;
    }
    return letterType == 'Cancellation Phase 4';
  }

  get hasVariations(): boolean {
    return (getMessageConfig(this.contactForm.controls['letterTypeToSend'].value)?.variations.length > 0);
  }

  get isRenewalWithNoPrice(): boolean {
    const emailType: string = this.contactForm.controls['letterTypeToSend'].value;
    if (emailType && emailType.toLocaleLowerCase().includes('renewal')) {
      const renewalPriceString: string = this.order.renewalInformation.renewalPrice;
      if (!renewalPriceString || !PRICE_REGEX.test(renewalPriceString)) {
        return true;
      }
      const renewalPrice: number = Number(renewalPriceString);
      return (renewalPrice <= 0);
    }
    return false;
  }

  sendLetter() {
    this.resultMessage = '';
    this.sendDisabled = true;
    const params: ProposedMessage = {
      'brand': this.order.website.title,
      'messageType': this.contactForm.controls['letterTypeToSend'].value,
      'methods': ['letter'],
      'tdCode': this.order.alarmUserDetails.tdCode,
      'orderId': this.order._id,
      'status': 'Ready to Send',
      'renewalDate': this.order.renewalInformation.renewalDate,
      'paymentDueDate': this.order.renewalInformation.paymentDueDate,
      'contactDetails': {
        'source': this.contactForm.controls['source'].value,
        'firstName': this.contactForm.controls['firstName'].value,
        'lastName': this.contactForm.controls['lastName'].value,
        'reFirstName': this.contactForm.controls['reFirstName'].value,
        'reLastName': this.contactForm.controls['reLastName'].value,
        'email': '',
        'mobile': '',
        'address': this.contactForm.controls['address'].value.split('\n')
          .filter((addressLine: string) =>
            addressLine.trim().length > 0,
          ),
      },
    };
    if (this.needsPaymentDetails) {
      params.cancellationInfo = {
        'derivedPlan': this.cancellationData.derivedPlan,
        'link': '',
        'unitCost': this.contactForm.controls['unitCost'].value,
        'vatCost': this.contactForm.controls['vatCost'].value,
        'equipCost': this.contactForm.controls['equipCost'].value,
        'equipName': this.contactForm.controls['equipName'].value,
      }
    }
    if (this.contactForm.controls['letterTypeToSend'].value.startsWith('Renewal')) {
      params.renewalData = {
        'showDdOffer': (this.order.accountDetails.planType == 'annual'),
        'renewalLink': '',
      };
    }
    if (this.hasVariations) {
      // Ideally would have been called messageVariation, but to save changing all existing records leave it called emailVariation
      params.emailVariation = this.letterVariation.value;
    }
    this.proposedMessageService.addMessage(params).subscribe((response: SingleRecordResponse<ProposedMessage>) => {
      this.sendDisabled = false;
      if (response.success) {
        this.resultMessage = 'Letter queued for sending';
        this.resultSuccess = true;
      } else {
        this.resultMessage = `Error queuing letter for sending ${response.message}`;
        this.resultSuccess = false;
      }
      
    }, (err: Error) => {
      this.sendDisabled = false;
      this.resultMessage = `Error queuing letter for sending ${err.message}`;
      this.resultSuccess = false;
    });
  }

  isOkToSend(): boolean {
    return this.contactForm.valid && !this.sendDisabled &&
      // This part makes sure if one provided both are when regarding is optional
      (this.hasRegardingFirstName == this.hasRegardingLastName) &&
      !this.isRenewalWithNoPrice;
  }

  closeDialog() {
    this.resultMessage = '';
    this.closeModal.emit();
  }

  selectedContactChange(): void {
    if (this.selectedContactDetails) {
      this.contactForm.patchValue({
        'source': this.selectedContactDetails.source,
        'firstName': this.selectedContactDetails.firstName,
        'lastName': this.selectedContactDetails.lastName,
        'address': this.selectedContactDetails.address.join('\n'),
      });
    } else {
      this.contactForm.patchValue({
        'source': 'Manually Entered',
        'firstName': '',
        'lastName': '',
        'address': '',
      });
    }
  }

  get reFirstName(): AbstractControl {
    return this.contactForm.get('reFirstName');
  }

  get reLastName(): AbstractControl {
    return this.contactForm.get('reLastName');
  }

  get letterVariation(): AbstractControl {
    return this.contactForm.get('letterVariation');
  }

  selectedTypeChange(): void {
    if (this.needsPaymentDetails) {
      if (this.cancellationData) {
        this.contactForm.patchValue({
          'unitCost': this.cancellationData.unitCost,
          'vatCost': this.cancellationData.vatCost,
          'equipCost': this.cancellationData.equipCost,
          'equipName': this.cancellationData.equipName,
        });
      } else {
        this.contactForm.patchValue({
          'unitCost': '',
          'vatCost': '',
          'equipCost': '',
          'equipName': '',
        });
      }
      this.contactForm.controls['unitCost'].enable();
      this.contactForm.controls['vatCost'].enable();
      this.contactForm.controls['equipCost'].enable();
      this.contactForm.controls['equipName'].enable();
    } else {
      this.contactForm.controls['unitCost'].disable();
      this.contactForm.controls['vatCost'].disable();
      this.contactForm.controls['equipCost'].disable();
      this.contactForm.controls['equipName'].disable();
    }
    let selectFirstContact: boolean = false;
    if (this.areRegardingFieldsAllowed) {
      this.contactForm.patchValue({
        'reFirstName': this.order.alarmUserDetails.firstName,
        'reLastName': this.order.alarmUserDetails.lastName,
      });
      this.contactDetailsToPickFrom = this.availableContactDetails.filter((contact: SelectItem<ContactDetails>) => 
        !contact.value.source.includes('User')
      );
      if (this.selectedContactDetails?.source.includes('User')) {
        selectFirstContact = true;
      }
    } else {
      this.contactForm.patchValue({
        'reFirstName': '',
        'reLastName': '',
      });
      if (this.toUsersOnly) {
        this.contactDetailsToPickFrom = this.availableContactDetails.filter((contact: SelectItem<ContactDetails>) => 
          contact.value.source.includes('User')
        );
        if (!this.selectedContactDetails?.source.includes('User')) {
          selectFirstContact = true;
        }
      } else {
        this.contactDetailsToPickFrom = this.availableContactDetails;
      }
    }
    // Have to do this rather than enable/disable fields as might be optional
    if (this.areRegardingFieldsRequired) {
      this.reFirstName.setValidators(Validators.required);
      this.reLastName.setValidators(Validators.required);
    } else {
      this.reFirstName.clearValidators();
      this.reLastName.clearValidators();
    }
    if (selectFirstContact && (this.contactDetailsToPickFrom.length == 0)) {
      this.selectedContactDetails = undefined;
      this.contactForm.patchValue({
        'source': 'Manually Entered',
        'firstName': '',
        'lastName': '',
        'address': '',
      });
    } else if (selectFirstContact || (this.contactDetailsToPickFrom.length == 1)) {
      this.selectedContactDetails = this.contactDetailsToPickFrom[0].value;
      this.contactForm.patchValue({
        'source': this.selectedContactDetails.source,
        'firstName': this.selectedContactDetails.firstName,
        'lastName': this.selectedContactDetails.lastName,
        'address': this.selectedContactDetails.address.join('\n'),
      });
    }
    if (this.hasVariations) {
      this.letterVariation.enable();
    } else {
      this.letterVariation.disable();
    }
    this.letterVariation.updateValueAndValidity();
    this.reFirstName.updateValueAndValidity();
    this.reLastName.updateValueAndValidity();
  }

  addressSearch() {
    if (!this.searchPostCode) {
      return;
    }
    this.addressResults = [];
    this.allowAddressManualEntry = false;
    this.addressSearchError = '';
    this.getAddrClient.find(this.searchPostCode).then((addressResult: Result<FindSuccess,FindFailed>) => {
      this.addressResults = getLookupFromGetAddressResult(this.searchPostCode, addressResult);
      if (!addressResult.isSuccess) {
        this.addressSearchError = addressResult.toFailed().message;
        console.error('Letter Address search failed. Error:', this.addressSearchError);
      } else if (this.addressResults.length <= 2) {
        this.addressSearchError = 'No matches found';
      }
    }).catch((error: any) => {
      console.error('Letter Address search failed. Error:', error);
    });
  }

  setAddress(event: DropDownChangeEvent<Address>): void {
    const selectedAddress: Address = event.value;
    if (!selectedAddress || !selectedAddress.validated) {
      this.allowAddressManualEntry = true;
      return;
    }
    this.allowAddressManualEntry = false;
    this.contactForm.patchValue({
      'address': getFormattedAddress(selectedAddress),
    })
  }
}
