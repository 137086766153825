import {ProposedMessageService} from './proposed-message.service';
import {MessageService, SelectItem} from 'primeng/api';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {getBrandColour, getBrandSelectItems, getBrandBackgroundColour} from '../lookups/brands';
import {isValidUkMobile} from '../validators/phone-number.validator';
import {isValidAddressArray} from '../validators/unstructured-address.validator';
import {ProposedMessage} from '../models/proposedMessage.model';
import {ContactDetails} from '../models/contactDetails.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {getInvalidOrInternalEmailsFromMultiEmailString} from '../validators/email.validator';
import {Table} from 'primeng/table';
import {renewalTypeDisplayLookup, renewalTypeSelectItems} from '../lookups/renewalTypes';
import {Column} from '../models/column.model';
import {LocksSocketService} from '../sockets/locks-socket.service';
import {MessageUpdatesSocketService} from '../sockets/message-updates-socket.service';
import { HttpParams } from '@angular/common/http';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {Title} from '@angular/platform-browser';
import {tdCodeMatchModeOptions} from '../lookups/filterOptions';
import {MessageLockData} from '../models/socket-io/messageLockData.model';
import {OrderLockData} from '../models/socket-io/orderLockData.model';
import moment from 'moment-timezone';
import {ExcelExportService} from '../post-order/excel-export.service';

enum ExportOptions {
  ALL = 'All',
  FILTERED = 'Filtered',
}

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss'],
  providers: [MessageService]
})
export class MessagesListComponent implements OnInit, OnDestroy {

  constructor(
    private proposedMessageService: ProposedMessageService,
    private locksSocket: LocksSocketService,
    private messageUpdatesSocket: MessageUpdatesSocketService,
    private messageService: MessageService,
    private title: Title,
    private excelService: ExcelExportService,
  ) { }
  ExportOptions = ExportOptions;
  allMessages: ProposedMessage[] = [];
  cols: Column[] = [
    { field: 'brand', header: 'Brand' },
    { field: 'messageType', header: 'Message' },
    { field: 'methods', header: 'Method' },
    { field: 'tdCode', header: 'TD Code' },
    { field: 'generated', header: 'Date Generated' },
    { field: 'renewalType', header: 'Renewal Type' },
    { field: 'status', header: 'Status' },
    { field: 'daysToGo', header: 'Days to Go' },
    { field: 'action', header: 'Actions' },
  ];
  searchableFields: string[] = this.cols.map((e: Column) => e.field);
  getBrandColour = getBrandColour;
  getBrandBackgroundColour = getBrandBackgroundColour;
  orderLockList: OrderLockData[] = [];
  messageLockList: MessageLockData[] = [];
  brands: SelectItem<string>[];
  statusType: SelectItem<string>[] = [
    { label: 'Awaiting Review', value: 'Awaiting Review' },
    { label: 'Ready To Send', value: 'Ready to Send' },
    { label: 'Rejected, Do Not Send', value: 'Rejected, Do not Send' },
    { label: 'Sent', value: 'Sent' },
  ];
  messageTypes: SelectItem<string>[] = [];
  renewalTypes: SelectItem<string>[] = renewalTypeSelectItems;
  renewalTypeDisplayLookup: {[lookupName: string]: string} = renewalTypeDisplayLookup;
  loading: boolean = false;
  isValidUkMobile = isValidUkMobile;
  isValidAddressArray = isValidAddressArray;
  isMessageDetailViewId: string = null;
  @ViewChild('dt', {static: false})
  messageListTable: Table;
  first: number;
  updateReceived: boolean;
  tdCodeMatchModeOptions: SelectItem<string>[] = tdCodeMatchModeOptions;
  selectedMessageTypes: string[] = [];
  selectedStatuses: string[] = [];
  showInvalidOnly: boolean;

  ngOnInit() {
    this.first = 0;
    this.brands = getBrandSelectItems();
    this.updateReceived = false;
    this.showInvalidOnly = false;

    this.locksSocket.on('lockList', (data: OrderLockData[]) => {
      this.orderLockList = data;
    })
    this.locksSocket.emit('getLocked');

    this.locksSocket.on('messageLockList', (data: MessageLockData[]) => {
      this.messageLockList = data;
    });
    this.locksSocket.emit('getLockedMessage');

    this.messageUpdatesSocket.on('updateMessage', (data: ProposedMessage) => {
      this.updateReceived = true;
      this.first = this.messageListTable.first;
      // If messages of the status the message now has should not be displayed remove it
      if ((data.status === 'Archived') ||
          ((this.selectedStatuses.length > 0) && !this.selectedStatuses.includes(data.status))) {
        this.allMessages = this.allMessages.filter((message: ProposedMessage) => 
          message._id !== data._id
        );
      } else {
        this.allMessages = [...[data], ...this.allMessages.filter((message: ProposedMessage) => 
          message._id !== data._id
        )];
      }
    });

    this.proposedMessageService.getCrmMessageTypes().subscribe((response: MultiRecordResponse<SelectItem<string>>) => {
      this.messageTypes = response.data;
    });
    this.title.setTitle('CRM Messages');
  }

  loadMessages() {
    this.loading = true;
    const params: HttpParams = new HttpParams({
      fromObject: {
        'messageTypes': this.selectedMessageTypes, 
        'statuses': this.selectedStatuses,
      }
    });
    this.proposedMessageService.getSelectedMessages(params).subscribe((response: MultiRecordResponse<ProposedMessage>) => {
      this.updatePageWithResponse(response);
    }, (err: Error) => {
        this.loading = false;
        this.showError('Something went wrong', `Error retrieving messages. Error: ${err.message}`);
    });
  }

  ngOnDestroy() {
    this.messageUpdatesSocket.removeAllListeners();
    this.locksSocket.removeAllListeners();
  }

  updatePageWithResponse(response: MultiRecordResponse<ProposedMessage>) {
    this.loading = false;
    if (response.success) {
      this.allMessages = response.data;
    } else {
      this.showError('Something went wrong', `Error retrieving messages. Error: ${response.message}`);
    }
  }

  isOrderLocked(_id: string) {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id).length > 0;
  }

  orderLockedBy(_id: string) {
    return this.orderLockList.filter((lockedOrder: OrderLockData) => lockedOrder.orderId == _id)[0].user;
  }

  isMessageLocked(messageId: string) {
    return this.messageLockList.filter((lockedMsg: MessageLockData) => lockedMsg.messageId == messageId).length > 0;
  }

  messageLockedBy(messageId: string) {
    return this.messageLockList.filter((lockedMsg: MessageLockData) => lockedMsg.messageId == messageId)[0].user;
  }

  openMessageDetailComponent(messageId: string) {
    this.isMessageDetailViewId = messageId;
  }

  closeMessageDetailView(event: SimpleResponse) {
    this.isMessageDetailViewId = null;
    if (event.success) {
      if (event.message !== 'close') {
        this.showSuccess();
      }
    } else {
      this.showError('Something went wrong',  `Update failed. Error: ${event.message}`);
    }
  }

  isEmailFieldValid(emailAddressField: string): boolean {
    if (!emailAddressField || (emailAddressField.length == 0)) {
      return false;
    }
    const invalidEmails: string[] = getInvalidOrInternalEmailsFromMultiEmailString(emailAddressField);
    if (invalidEmails.length === 0) {
      return true;
    }
    return false;
  }

  hasValidContactForOneOrMoreMethods(rowData: ProposedMessage): boolean {
    const contactDetails: ContactDetails = rowData.contactDetails;
    for (let index: number = 0; index < rowData.methods.length; index++) {
      const method: string = rowData.methods[index];
      switch (method) {
        case 'email':
          if (this.isEmailFieldValid(contactDetails.email)) {
            return true;
          }
          break;
        case 'text':
          if (isValidUkMobile(contactDetails.mobile)) {
            return true;
          }
          break;
        case 'letter':
          if (isValidAddressArray(contactDetails.address)) {
            return true;
          }
          break;
        default:
          break;
      }
    }
    return false;
  }

  showSuccess() {
    this.messageService.add({
      life: 1000,
      severity: 'success',
      summary: 'Success Updates!',
      detail: 'Changes Successfuly Applied!'
    });
  }

  showError(summary: string, detail: string) {
    this.messageService.add({
      life: 300000,
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }

  onFilter($event: any) {
    if (this.messageListTable.filteredValue == null) {
      this.messageListTable.filteredValue = this.messageListTable.value;
    }
    if (this.showInvalidOnly) {
      this.messageListTable.filteredValue = this.messageListTable.filteredValue.filter((msg: ProposedMessage) => {
        if ((msg.contactDetails == null)) {
          return false;
        }
        let hasInvalidContactDetails: boolean = false;
        msg.methods.forEach((method: string) => {
          // short circuit to avoid running validation once we know it's invalid
          if (hasInvalidContactDetails) {
            return;
          }
          switch (method) {
            case 'email':
              if (!this.isEmailFieldValid(msg.contactDetails.email)) {
                hasInvalidContactDetails = true;
              }
              break;
            case 'text':
              if (!this.isValidUkMobile(msg.contactDetails.mobile)) {
                hasInvalidContactDetails = true;
              }
              break;
            case 'letter':
              if (!this.isValidAddressArray(msg.contactDetails.address)) {
                hasInvalidContactDetails = true;
              }
              break;
          }
        });
        return hasInvalidContactDetails;
      });
      this.messageListTable.totalRecords = this.messageListTable.filteredValue.length;
    }
    
    // If the re-run of the filter is triggered by an update want to go back to the page we were on
    if (this.updateReceived) {
      this.messageListTable.first = Math.min(this.first, $event.filteredValue.length);
      // reset the flag
      this.updateReceived = false;
    }
  }

  globalFilter($event: Event, filterType: string): void {
    this.messageListTable.filterGlobal(($event.target as HTMLInputElement).value, filterType);
  }

  applyFilter($event: Event, field: string, filterType: string): void {
    this.messageListTable.filter(($event.target as HTMLInputElement).value, field, filterType);
  }

  changeMethodFilter() {
    this.messageListTable.filter([], 'methods', 'in');
  }

  exportMessages(exportOption: ExportOptions): void {
    let recordsToExport: ProposedMessage[];
    if (ExportOptions.ALL == exportOption) {
      recordsToExport = this.allMessages;
    } else if (ExportOptions.FILTERED == exportOption) {
      recordsToExport = this.messageListTable.filteredValue? this.messageListTable.filteredValue: this.allMessages;
    }
    const formattedForExport: any[] = recordsToExport.map((proposedMsg: ProposedMessage) => {
      return {
        'Brand': proposedMsg.brand,
        'Td Code': proposedMsg.tdCode,
        'Message Type': proposedMsg.messageType,
        'Methods': proposedMsg.methods.join(', '),
        'Generated': moment.tz(proposedMsg.generated, 'Europe/London').format('DD/MM/YYYY'),
        'Status': proposedMsg.status,
        'Rejection Reason': proposedMsg.rejectionReason,
        'First Name': proposedMsg.contactDetails.firstName,
        'Last Name': proposedMsg.contactDetails.lastName,
        'Re First Name': proposedMsg.contactDetails.reFirstName,
        'Re Last Name': proposedMsg.contactDetails.reLastName,
        'Email': proposedMsg.contactDetails.email,
        'Mobile': proposedMsg.contactDetails.mobile,
        'Address': proposedMsg.contactDetails.address.join('\n'),
        'Renewal Date': proposedMsg.renewalDate,
        'Payment Due Date': proposedMsg.paymentDueDate,
        'Days to Go': proposedMsg.daysToGo,
        'Renewal Type': proposedMsg.renewalType,
        'Email Variation': proposedMsg.emailVariation,
        'Payment For': proposedMsg.paymentFor,
        'Payment Amount': proposedMsg.paymentAmount,
      }
    });
    this.excelService.exportAsExcelFile({'Messages': formattedForExport}, `${exportOption}_messages`);
  }
}
