import moment from 'moment-timezone';
import {DatedPrice} from '../models/datedPrice.model';
import {Order, OrderTag} from '../models/order.model';
import {Hardware} from '../models/hardware.model';
import {HardwareSet} from '../models/hardwareSet.model';
import {PlanCodeAndPrice} from '../models/planCodeAndPrice.model';
import {RenewalDiscount} from '../models/renewalDiscount.model';
import {AccountService} from '../models/accountService.model';
import {FutureRenewalDetails, RenewalDateDetails} from '../models/responses/functionReturns/renewalDateDetails.model';
import {EX_TO_INC_VAT_MULTIPLIER} from './helperFunctions';
import {HardwareAndSets} from '../models/responses/functionReturns/hardwareAndSets.model';
import {HardwareId} from '../models/mongoose-populated-ids/hardwareId.model';

const PRICE_REGEX: RegExp = /^\d+\.?\d*$/;
const COLLECTION_DATES: number[] = [10, 25];

/**
 * Get the correct price for the order date, from either the under one year prices or from the dated prices
 * @param {boolean} recentOrder whether the order is recent (under 12 months old)
 * @param {string} planType the plan type of the order
 * @param {string} orderDateYMD the order date in YMD format
 * @param {DatedPrice[]} pricesExVat the dated prices excluding VAT
 * @param {number} recentQuarterlyPrice the price for quarterly orders under 1 year old
 * @param {number} recentAnnualPrice the price for annual orders under 1 year old
 * @return {number|undefined} the correct price based on order date, or undefined if none found
 */
function getPriceForOrderDate(recentOrder: boolean, planType: string, orderDateYMD: string, pricesExVat: DatedPrice[],
    recentQuarterlyPrice: number, recentAnnualPrice: number): number|undefined {
  if (recentOrder) {
    switch (planType) {
      case 'annual':
        if (recentAnnualPrice) {
          return recentAnnualPrice;
        }
        break;
      case 'quarterly':
      case 'monthly':
        if (recentAnnualPrice) {
          return recentQuarterlyPrice;
        }
        break;
    }
  }
  // If order > 1 year old, or there is no specific < 1 year old price use the price with the appropriate date range
  const correctPrice: DatedPrice|undefined = pricesExVat.find((datedPrice: DatedPrice) => {
    if (!datedPrice.fromDate) {
      return (datedPrice.toDate >= orderDateYMD);
    }
    if (!datedPrice.toDate) {
      return (datedPrice.fromDate <= orderDateYMD);
    }
    return ((datedPrice.fromDate <= orderDateYMD) && (datedPrice.toDate >= orderDateYMD));
  });
  if (correctPrice) {
    switch (planType) {
      case 'annual':
        return correctPrice.annual;
      case 'quarterly':
      case 'monthly':
        return correctPrice.quarterly;
    }
  }
  return undefined;
}
  
/**
 * Calculate the plan code and renewal price for the specified hardware and sets for the given plan type, 
 * vat status and order date and report any errors
 * @param {string} planType the plan type to calculate for (monthly/quarterly/annual/lifetime)
 * @param {string} vatStatus the customer's VAT status
 * @param {string} orderDate the order date
 * @param {HardwareSet[]} hardwareSets the hardware sets to include in the plan code and price
 * @param {Hardware[]} hardwareArray the hardware to include in the plan code and price
 * @param {RenewalDiscount[]} discounts which discounts to apply
 * @return {PlanCodeAndPrice} the plan code, price and any errors
 */
function getPlanCodeAndPrice(planType: string, vatStatus: string, orderDate: string, hardwareSets: HardwareSet[], 
    hardwareArray: Hardware[], discounts: RenewalDiscount[], services: AccountService[]): PlanCodeAndPrice {
  let planCode: string = '';
  let planFrequencyCode: string = '';
  let baseRenewalPrice: number = 0;
  let renewalPrice: number = 0;
  let calculatePlanPrice: boolean = false;
  let firstPlanCode: boolean = true;
  const errors: string[] = [];
  const additionalHardware: Hardware[] = [];
  const monitoringOnlyHardware: Hardware[] = [];
  const createdMoment: moment.Moment = moment.tz(orderDate, 'Europe/London');
  const orderDateYMD: string = createdMoment.format('YYYY-MM-DD');
  const currentMoment: moment.Moment = moment.tz('Europe/London');
  const todayDateYMD: string = currentMoment.format('YYYY-MM-DD');
  const recentOrder: boolean = (currentMoment.diff(createdMoment, 'months') <= 11);

  /**
   * Add the correct price, based on order date, plan type and vat status to the renewal price
   * @param {DatedPrice[]} pricesExVat the array of dated prices for the hardware/set
   * @param {string }description the description of the hardware/set for any error message
   * @param {number} recentQuarterlyPrice the price for quarterly orders under 1 year old
   * @param {number} recentAnnualPrice the price for annual orders under 1 year old
   */
  function addCorrectPriceToPlan(pricesExVat: DatedPrice[], description: string, recentQuarterlyPrice: number,
      recentAnnualPrice: number): void {
    if (calculatePlanPrice) {
      const priceForOrderDate: number|undefined =
        getPriceForOrderDate(recentOrder, planType, orderDateYMD, pricesExVat, recentQuarterlyPrice, recentAnnualPrice);
      if (!priceForOrderDate) {
        errors.push(`No price for ${orderDateYMD} for ${description}`);
      } else if (vatStatus == 'exempt') {
        baseRenewalPrice += priceForOrderDate;
      } else {
        baseRenewalPrice += (priceForOrderDate * EX_TO_INC_VAT_MULTIPLIER);
      }
    }
  }

  /**
   * Process the hardware set to update the plan code and prices as appropriate
   * @param {HardwareSet} hardwareSet To hardware set to process
   */
  function processHardwareSet(hardwareSet: HardwareSet): void {
    const overridePlanSymbol: string = hardwareSet.overridePlanSymbol;
    addCorrectPriceToPlan(hardwareSet.overridePricesExVat, `Set: ${hardwareSet.title}`,
        hardwareSet.overrideRecentQuarterlyPrice, hardwareSet.overrideRecentAnnualPrice);
    if (overridePlanSymbol) {
      planCode += `${firstPlanCode? '': '+'}${overridePlanSymbol}`;
      firstPlanCode = false;
    }
  }

  /**
   * Process each piece of hardware in the array, updating the plan code and price as required
   * @param {Hardware[]} hardwareArray The hardware to use to update the plan code and price
   */
  function processHardwareArray(hardwareArray: Hardware[]) {
    hardwareArray.forEach((curHardware: Hardware) => {
      if (curHardware.planSymbol) {
        planCode += `${firstPlanCode? '': '+'}${curHardware.planSymbol}`;
        firstPlanCode = false;
      }
      addCorrectPriceToPlan(curHardware.pricesExVat, `Hardware: ${curHardware.title}`,
        curHardware.recentQuarterlyPrice, curHardware.recentAnnualPrice);
    });
  }

  try {
    switch (planType) {
      case 'lifetime':
        planFrequencyCode = 'L';
        break;
      case 'annual':
        planFrequencyCode = 'A';
        calculatePlanPrice = true;
        break;
      case 'quarterly':
      case 'monthly':
        planFrequencyCode = 'M';
        calculatePlanPrice = true;
        break;
      default:
        errors.push('Invalid plan type');
        break;
    }

    hardwareArray.forEach((currentHardware: Hardware) => {
      if (currentHardware.category == 'Monitoring Only') {
        monitoringOnlyHardware.push(currentHardware);
      } else {
        additionalHardware.push(currentHardware);
      }
    });

    // Process sets before additional hardware to keep the plan code in the traditional order
    hardwareSets.sort((setA: HardwareSet, setB: HardwareSet) => {
      if (setA.overridePlanSymbol.includes('#') && !setB.overridePlanSymbol.includes('#')) {
        // Only set A has a #, so it should come first
        return -1;
      }
      if (!setA.overridePlanSymbol.includes('#') && setB.overridePlanSymbol.includes('#')) {
        // Only set B has a #, so it should come first
        return 1;
      }
      // Either both or neither contains #, so compare names
      return setA.title.localeCompare(setB.title);
    });
    // Process sets before additional hardware to keep the plan code in the traditional order
    hardwareSets.forEach((hardwareSet: HardwareSet) => {
      processHardwareSet(hardwareSet);
    });
    processHardwareArray(additionalHardware);
    processHardwareArray(monitoringOnlyHardware);
    services.forEach((curService: AccountService) => {
      if (curService.planSymbol) {
        planCode += `${firstPlanCode? '': '+'}${curService.planSymbol}`;
        firstPlanCode = false;
      }
      addCorrectPriceToPlan(curService.servicePricesExVat, `Service: ${curService.title}`, 0, 0);
    });

    // Allow for monthly customers
    if (planType == 'monthly') {
      baseRenewalPrice = baseRenewalPrice / 3.00;
    }

    switch (vatStatus) {
      case 'exempt':
        break;
      case 'not exempt':
        planCode += 'V';
        break;
      default:
        errors.push('Invalid VAT status');
        break;
    }

    renewalPrice = baseRenewalPrice;

    // No point taking discounts off invalid frozen price, or the base price is zero
    if ((baseRenewalPrice > 0) && (discounts.length > 0)) {
      discounts.forEach((renewalDiscount: RenewalDiscount) => {
        // If either the discount does not expire, or has not yet expired
        if ((!renewalDiscount.discountExpiry) ||
            (!!renewalDiscount.discountExpiry && (renewalDiscount.discountExpiry > todayDateYMD))) {
          renewalPrice -= (renewalDiscount.discount as number);
        }
      });
      if (renewalPrice <= 0) {
        renewalPrice = 0;
        errors.push('Discounts equal or exceed renewal price');
      }
    }

    // Nothing has been added to the plan code, so blank it (to remove the frequency and VAT indicators)
    if (firstPlanCode) {
      planCode = '';
    } else {
      planCode = planCode.replace(/#/g, planFrequencyCode);
    }

    return {
      'planCode': planCode,
      'baseRenewalPrice': baseRenewalPrice.toFixed(2),
      'renewalPrice': renewalPrice.toFixed(2),
      'errors': errors,
    };
  } catch (error: any) {
    errors.push(`Error getting plan code and price. Error: ${error.message}`);
    return {
      'planCode': '',
      'baseRenewalPrice': '0.00',
      'renewalPrice': '0.00',
      'errors': errors,
    };
  }
}

/**
 * Calculate the first future renewal date from the given start date and the first one for which there is sufficient time to adjust the payment
 * @param {moment.Moment} currentMoment the start of the current day
 * @param {moment.Moment} start the point to start from when calculating the renewal dates
 * @param {number} renewalPeriod the number of months for 1 renewal period
 * @param {string} renewalType the means the customer is paying for the renewal by
 * @param {boolean} paymentAtRenewal whether the customer's payment due date is their renewal date, rather than before
 * @return {FutureRenewalDetails} the cacluated renewal dates
 */
function getFutureRenewalDetails(currentMoment: moment.Moment, start: moment.Moment, renewalPeriod: number, renewalType: string,
  paymentAtRenewal: boolean
): FutureRenewalDetails {
  // Clone to avoid changing the passed in value and set to start of day
  const renewalMoment: moment.Moment = start.clone().startOf('day');
  const result: FutureRenewalDetails = {
    firstFutureRenewal: undefined,
    firstAdjustableRenewal: undefined,
    renewalsBeforeAjustable: 0,
  };
  // Get the renewal moment to the first future date
  while (renewalMoment.isSameOrBefore(currentMoment)) {
    renewalMoment.add(renewalPeriod, 'months').startOf('day');
  }
  // Clone and save before adjusting for the first adjustable
  result.firstFutureRenewal = renewalMoment.clone();
  const paymentCannotBeChangedAfter: moment.Moment = renewalMoment.clone();
  if (renewalType == 'directDebit') {
    if (!paymentAtRenewal) {
      let collectionDateCount: number = 0;
      do {
        paymentCannotBeChangedAfter.subtract(1, 'day');
        if (COLLECTION_DATES.includes(paymentCannotBeChangedAfter.date())) {
          collectionDateCount++;
        }
      } while (collectionDateCount < 2);
    }
    // Need an additional week before collection date to give time to change
    paymentCannotBeChangedAfter.subtract(1, 'week');
  } else {
    if (!paymentAtRenewal) {
      // Starting point is payment date is 1 month before renewal
      paymentCannotBeChangedAfter.subtract(1, 'month');
    }
    if (renewalType == 'goCardless') {
      // Need two weeks before collection date to give time to change (has to be changed before 1 week before)
      paymentCannotBeChangedAfter.subtract(2, 'weeks');
    } else {
      // Need an additional week before collection date to give time to change
      paymentCannotBeChangedAfter.subtract(1, 'week');
    }
  }
  while (paymentCannotBeChangedAfter.isSameOrBefore(currentMoment)) {
    // Advance paymentCannotBeChangedAfter to know when to stop advancing
    paymentCannotBeChangedAfter.add(renewalPeriod, 'months').startOf('day');
    // Advance nextRenewalMoment at the same time as this is the related renewal
    renewalMoment.add(renewalPeriod, 'months').startOf('day');
    result.renewalsBeforeAjustable++;
  }
  result.firstAdjustableRenewal = renewalMoment;
  return result;
}

/**
 * Calculate the renewal date details (first future and first adjustable) from the order passed in from first principles.
 * If the renewal date is held in the CRM calculate values using this as a starting point as well and return the value
 * held in the CRM for the next renewal
 * @param {Order} order the order the renewal date details are being calculated for
 * @return {RenewalDateDetails} the calculated renewal dates from first principles and from CRM renewal date if set.
 */
function getRenewalDateDetails(order: Order): RenewalDateDetails {
  const currentMoment: moment.Moment = moment.tz('Europe/London').startOf('day');
  const result: RenewalDateDetails = {
    'crmStoredNextRenewal': undefined,
    'hasSixWeeksFree': false,
    'crm': undefined,
    'calculated': undefined,
  };
  let renewalPeriod: number = 1;
  switch (order.accountDetails.planType) {
    case 'quarterly':
      renewalPeriod = 3;
      break;
    case 'annual':
      renewalPeriod = 12;
      break;
  }
  
  const paymentAtRenewal: boolean = order.tags.some((tag: OrderTag) => (tag.tagID.tagName == 'Payment Due on Renewal Date'));
  if (order.renewalInformation.renewalDate) {
    const crmRenewalMoment: moment.Moment = moment.tz(order.renewalInformation.renewalDate, 'Europe/London');
    if (crmRenewalMoment.isValid()) {
      result.crmStoredNextRenewal = crmRenewalMoment;
      result.crm = getFutureRenewalDetails(currentMoment, crmRenewalMoment, renewalPeriod, order.renewalInformation.renewalType, paymentAtRenewal);
    }
  }
  const calcRenewalMoment: moment.Moment = moment.tz(order.created, 'Europe/London').startOf('day');
  result.hasSixWeeksFree = order.tags.some((tag: OrderTag) =>
    ['Norfolk CC', 'NCC 6 WK Free'].includes(tag.tagID.tagName)
  );
  if (result.hasSixWeeksFree) {
    calcRenewalMoment.add(6, 'weeks');
  }
  if (order.renewalInformation && order.renewalInformation.freemonths && /^\d+$/.test(order.renewalInformation.freemonths)) {
    const freeMonths: number = parseInt(order.renewalInformation.freemonths, 10);
    calcRenewalMoment.add(freeMonths, 'months');
  }
  result.calculated = getFutureRenewalDetails(currentMoment, calcRenewalMoment, renewalPeriod, order.renewalInformation.renewalType, paymentAtRenewal);
  return result;
}

/**
 * Get the priority order of the set based on the accessory in the set (generally most expensive accessory first)
 * @param {HardwareSet} set the set to get the priority order for
 * @return {number} the priority
 */
function getSetOrder(set: HardwareSet): number {
  if (set.overridePlanSymbol.includes('M')) {
    return 1;
  }
  if (set.overridePlanSymbol.includes('W')) {
    return 2;
  }
  if (set.overridePlanSymbol.includes('O')) {
    return 3;
  }
  if (set.overridePlanSymbol.includes('F')) {
    return 4;
  }
  return 5;
}

/**
 * Combines hardware items into sets where possible as sets prices and plan codes are not the sum of their parts
 * @param {Hardware[]} hardwareToConvert the list of hardware to convert into sets
 * @param {HardwareSet[]} setDefinitions the definitions of the sets to try to combine the hardware into
 * @return {HardwareAndSets} the hardware sets made, plus any hardware not combined into sets
 */
function convertHardwareToSets(hardwareToConvert: Hardware[], setDefinitions: HardwareSet[]): HardwareAndSets {
  const hardwareAndSets: HardwareAndSets = {
    'hardwareSets': [],
    'hardware': [],
  };
  const baseUnitsToMatch: Hardware[] = [];
  const accessoriesToMatch: Hardware[] = [];
  // Prioritise the sets with most expensive accessories to pair up first
  const setsToMatch: HardwareSet[] = setDefinitions.filter((set: HardwareSet) => 
    // can't handle 3 part sets (plus don't sell anymore)
    set.containedHardware.length == 2,
  ).sort((setA: HardwareSet, setB: HardwareSet) => {
    const setAOrder: number = getSetOrder(setA);
    const setBOrder: number = getSetOrder(setB);
    return setAOrder - setBOrder;
  });
  hardwareToConvert.forEach((hardware: Hardware) => {
    switch (hardware.category) {
      case 'Base Unit':
        baseUnitsToMatch.push(hardware);
        break;
      case 'Pendant/Accessory':
        if (['M', 'W', 'O', 'F', 'R'].includes(hardware.planSymbol)) {
          accessoriesToMatch.push(hardware);
        } else {
          // these don't pair with base units into a set
          hardwareAndSets.hardware.push(hardware);
        }
        break;
      default:
        // these don't pair with base units into a set
        hardwareAndSets.hardware.push(hardware);
        break;
    }
  });
  baseUnitsToMatch.forEach((baseUnit: Hardware) => {
    const setToUse: HardwareSet = setsToMatch.find((set: HardwareSet) => {
      const baseUnitIndex: number = set.containedHardware.findIndex((hardwareId: HardwareId) => 
        hardwareId._id == baseUnit._id
      );
      if (baseUnitIndex == -1) {
        return false;
      }
      const setAccessoryIndex: number = baseUnitIndex == 0? 1: 0;
      const setAccessoryId: HardwareId = set.containedHardware[setAccessoryIndex];
      const matchingAccessoryIndex: number = accessoriesToMatch.findIndex((accessory: Hardware) => 
          accessory._id == setAccessoryId._id
      );
      if (matchingAccessoryIndex == -1) {
        return false;
      }
      accessoriesToMatch.splice(matchingAccessoryIndex, 1);
      return true;
    });
    if (setToUse) {
      hardwareAndSets.hardwareSets.push(setToUse);
    } else {
      // Unmatched base units just go in as base units
      hardwareAndSets.hardware.push(baseUnit);
    }
  });
  // Any unmatched accessories go in on their own
  hardwareAndSets.hardware = hardwareAndSets.hardware.concat(accessoriesToMatch);
  return hardwareAndSets;
}

export {
  PRICE_REGEX,
  getPlanCodeAndPrice,
  getPriceForOrderDate,
  getRenewalDateDetails,
  convertHardwareToSets,
};
