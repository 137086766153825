import {Column} from '../models/column.model';

const orderSpreadsheetCols: Column[] = [
  { field: 'title', header: 'Brand', order: 10, keepOnHome: true },
  { field: 'tdCode', header: 'Td Code', order: 20, filterMatchMode: 'equals', usePColumnFilter: true, keepOnHome: true },
  { field: 'jontekCodes', header: 'Alarm Codes', order: 30, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'oldAlarmCodes', header: 'Old Alarm Codes', order: 35, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'status', header: 'Status', order: 40, keepOnHome: true },
  { field: 'created', header: 'Order Date', order: 50, keepOnHome: true },
  { field: 'customerName', header: 'Customer Name', order: 60, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'preferredName', header: 'Preferred Name', order: 70, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true },
  { field: 'otherName', header: 'Other Name', order: 80, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true },
  { field: 'notesContent', header: 'Notes', order: 90, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'noteCategory', header: 'Notes Categories', order: 100, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true },
  { field: 'vim', header: 'VIM', order: 110, filterMatchMode: 'startsWith', keepOnHome: true },
  // TODO remove for Hardware version
  { field: 'serial', header: 'Serial', order: 120, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'iDetailsprocessor', header: 'Initial Order Processor', order: 130, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'telephone', header: 'Telephone', order: 140, hide: true, usePColumnFilter: true, keepOnHome: true },
  { field: 'email', header: 'Email', order: 150, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'emailMarketing', header: 'Email Marketing', order: 152, hide: true, excludeFromGlobalSearch: true },
  { field: 'emailMarketingUpdated', header: 'Email Marketing Updated', order: 154, hide: true, excludeFromGlobalSearch: true },
  { field: 'emailTwoMarketing', header: 'Email Two Marketing', order: 156, hide: true, excludeFromGlobalSearch: true },
  { field: 'emailTwoMarketingUpdated', header: 'Email Two Marketing Updated', order: 158, hide: true, excludeFromGlobalSearch: true },
  { field: 'mobile', header: 'Mobile', order: 160, hide: true, keepOnHome: true },
  { field: 'mobileMarketing', header: 'Mobile Marketing', order: 162, hide: true, excludeFromGlobalSearch: true },
  { field: 'mobileMarketingUpdated', header: 'Mobile Marketing Updated', order: 164, hide: true, excludeFromGlobalSearch: true },
  { field: 'addressOne', header: 'Address Line 1', order: 170, filterMatchMode: 'contains', usePColumnFilter: true, keepOnHome: true },
  { field: 'addressTwo', header: 'Address Line 2', order: 180, hide: true, usePColumnFilter: true, keepOnHome: true },
  { field: 'city', header: 'City', order: 190, hide: true, usePColumnFilter: true, keepOnHome: true },
  { field: 'county', header: 'County', order: 200, hide: true, usePColumnFilter: true, keepOnHome: true },
  { field: 'postcode', header: 'Postcode', order: 210, filterMatchMode: 'contains', usePColumnFilter: true, keepOnHome: true },
  { field: 'dob', header: 'Date Of Birth', order: 220, hide: true, keepOnHome: true },
  { field: 'orderId', header: 'Website Order id', order: 230, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'plan', header: 'Plan', order: 240, filterMatchMode: 'contains', usePColumnFilter: true, keepOnHome: true },
  { field: 'renewalDate', header: 'Renewal Date', order: 250, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  // TODO add for Hardware version
  /*
  { field: 'baseRenewalPrice', header: 'Base Renewal Price', order: 260, hide: true },
  { field: 'frozenPrice', header: 'Frozen Price', order: 270, hide: true },
  */
  { field: 'renewalPrice', header: 'Renewal Price', order: 280, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  // TODO add for Hardware version
  // { field: 'renewalErrors', header: 'Renewal Errors', order: 290, hide: true },
  { field: 'paymentDueDate', header: 'Payment Due Date', order: 300, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'renewalType', header: 'Renewal Type', order: 310, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'outstandingActionsName', header: 'Action Title', order: 320, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'outstandingActionsRenewalDateTaken', header: 'Action Date', order: 330, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'outstandingActionsInitiatedDate', header: 'Date Action Initiated', order: 340, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'cancellationDate', header: 'Cancellation Date', order: 350, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'cancellationReason', header: 'Cancellation Reason', order: 360, hide: true, keepOnHome: true },
  { field: 'detailedCancellationReason', header: 'Detailed Cancellation Reason', order: 365, hide: true, keepOnHome: true },
  { field: 'otherReason', header: 'Cancellation Other Reason', order: 370, hide: true, usePColumnFilter: true, excludeFromGlobalSearch: true },
  { field: 'cancellationPersonReturning', header: 'Cancellation Person Returning', order: 380, hide: true, usePColumnFilter: true, excludeFromGlobalSearch: true },
  { field: 'cancellationTelephoneNumber', header: 'Cancellation Telephone Number', order: 390, hide: true, usePColumnFilter: true, excludeFromGlobalSearch: true },
  { field: 'cancellationEmail', header: 'Cancellation Email', order: 400, hide: true, usePColumnFilter: true, excludeFromGlobalSearch: true },
  { field: 'setToCancellingBy', header: 'Set to Cancelling By', order: 405, hide: true, keepOnHome: true },
  { field: 'paymentMethod', header: 'Payment Method', order: 410, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'accName', header: 'Account Contact Name', order: 420, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  // Exclude from global as also included as accName
  { field: 'accFirstName', header: 'Account Contact First Name', order: 430, hide: true, excludeFromGlobalSearch: true, filterMatchMode: 'contains', keepOnHome: true },
  // Exclude from global as also included as accName
  { field: 'accLastName', header: 'Account Contact Last Name', order: 440, hide: true, keepOnHome: true, filterMatchMode: 'contains' },
  { field: 'accOtherName', header: 'Account Contact Other Name', order: 450, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'accEmail', header: 'Account Contact Email', order: 460, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'accEmailMarketing', header: 'Account Contact Email Marketing', order: 462, hide: true, excludeFromGlobalSearch: true },
  { field: 'accEmailMarketingUpdated', header: 'Account Contact Email Marketing Updated', order: 464, hide: true, excludeFromGlobalSearch: true },
  { field: 'accTelephone', header: 'Account Contact Telephone', order: 470, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'accMobile', header: 'Account Contact Mobile', order: 480, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'accMobileMarketing', header: 'Account Contact Mobile Marketing', order: 482, hide: true, excludeFromGlobalSearch: true },
  { field: 'accMobileMarketingUpdated', header: 'Account Contact Mobile Marketing Updated', order: 484, hide: true, excludeFromGlobalSearch: true },
  { field: 'accRelationship', header: 'Account Contact Relationship', order: 490, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'vat', header: 'VAT', order: 500, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'monitoring', header: 'Monitoring', order: 510, hide: false, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'icouponCode', header: 'Coupon Code', order: 520, hide: true, filterMatchMode: 'contains', keepOnHome: true },
  { field: 'icouponAmount', header: 'Coupon Amount', order: 530, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true },
  { field: 'iDetailspayment', header: 'Initial Order Payment', order: 540, hide: true, excludeFromGlobalSearch: true },
  { field: 'iDetailsorderDate', header: 'initial Date', order: 550, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'iDetailsorderType', header: 'initial Order Type', order: 560, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'iDetailsrenewal', header: 'initial order Renewal', order: 570, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'iDetailsfirstName', header: 'Initial Order First Name', order: 580, hide: true, keepOnHome: true },
  { field: 'iDetailstelephone', header: 'Initial Order Telephone', order: 590, hide: true, keepOnHome: true },
  { field: 'iDetailsalarmUser', header: 'Initial Order Alarm User', order: 600, hide: true, keepOnHome: true },
  { field: 'iDetailslastName', header: 'Initial Order Last Name', order: 610, hide: true, keepOnHome: true },
  { field: 'sfOrderId', header: 'Initial Order SF Order ID', order: 620, hide: true, excludeFromGlobalSearch: true },
  { field: 'iDetailstransactionId', header: 'Initial Order Transaction ID', order: 630, hide: true, excludeFromGlobalSearch: true },
  { field: 'iDetailsemail', header: 'Initial Order Email', order: 640, hide: true, keepOnHome: true },
  { field: 'iDetailsinitialOrder', header: 'Initial Order ID', order: 650, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'iDetailsbilling', header: 'Initial Order Billing Address', order: 660, hide: true, excludeFromGlobalSearch: true },
  { field: 'iDetailsshipping', header: 'Initial Order Shipping Address ', order: 670, hide: true, excludeFromGlobalSearch: true },
  { field: 'iDetailsvatTotal', header: 'Initial Order VAT Total', order: 680, hide: true, excludeFromGlobalSearch: true },
  { field: 'iDetailstotal', header: 'Initial Order Total', order: 690, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'iDetailsnotes', header: 'Initial Order Notes', order: 700, hide: true, filterMatchMode: 'contains' },
  { field: 'iDetailsupgradeFallDetector', header: 'Initial Order Plan', order: 710, hide: true, excludeFromGlobalSearch: true },
  { field: 'iDetailsadditionalPendant', header: 'Initial Order Additional Pendant', order: 720, hide: true, excludeFromGlobalSearch: true },
  { field: 'iDetailskeysafe', header: 'Initial Order KeySafe', order: 730, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'dispatchDate', header: 'Dispatch Date', order: 740, hide: true, excludeFromGlobalSearch: true },
  { field: 'ShippingMethod', header: 'Shipping Method', order: 750, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'trackingNo', header: 'Tracking Number', order: 760, hide: true, filterMatchMode: 'startsWith', usePColumnFilter: true, excludeFromGlobalSearch: true },
  { field: 'cadOrderId', header: 'Click And Drop Order Id', order: 770, hide: true, excludeFromGlobalSearch: true },
  { field: 'returnDate', header: 'Return Date', order: 780, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'Tags', header: 'Tags', order: 790, hide: false, filterMatchMode: 'contains', excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'tagExpiryDates', header: 'Tag Expiry Dates', order: 800, hide: true, excludeFromGlobalSearch: true },
  { field: 'seconduserRelationship', header: 'Alarm User Relationship', order: 810, hide: true, excludeFromGlobalSearch: true },
  { field: 'planType', header: 'Plan Type', order: 820, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true, keepOnHome: true },
  // TODO start remove for Hardware version
  { field: 'planName', header: 'Plan Title', order: 830, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'planSerial', header: 'Plan Serial', order: 840, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'planYearMade', header: 'Plan Year of Manufacture', order: 850, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true},
  { field: 'planStatus', header: 'Plan Status', order: 860, hide: true, excludeFromGlobalSearch: true },
  { field: 'pendantType', header: 'Pendant Type', order: 870, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'pendantStatus', header: 'Pendant Status', order: 880, hide: true, excludeFromGlobalSearch: true },
  { field: 'pendantSerial', header: 'Pendant Serial', order: 890, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'pendantYearMade', header: 'Pendant Year of Manufacture', order: 900, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true},
  { field: 'planAddedAt', header: 'Plan Added Date', order: 910, hide: true, excludeFromGlobalSearch: true },
  { field: 'AdditionalEquipmentName', header: 'Additional Equipment Title', order: 920, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'AdditionalEquipmentSerial', header: 'Additional Equipment Serial', order: 930, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'additionalEquipmentYearMade', header: 'Additional Equipment Year of Manufacture', order: 940, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true},
  { field: 'AdditionalEquipmentStatus', header: 'Additional Equipment Status', order: 950, hide: true, excludeFromGlobalSearch: true },
  { field: 'AdditionalEquipmentAddedAt', header: 'Additional Equipment Added Date', order: 960, hide: true, excludeFromGlobalSearch: true },
  { field: 'ReplacementEquipmentSerial', header: 'Replacement Equipment Serial', order: 970, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'replacementEquipmentYearMade', header: 'Replacement Equipment Year of Manufacture', order: 980, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true},
  { field: 'ReplacementEquipmentName', header: 'Replacement Equipment Name', order: 990, hide: true, excludeFromGlobalSearch: true },
  { field: 'ReplacementEquipmentStatus', header: 'Replacement Equipment Status', order: 1000, hide: true, excludeFromGlobalSearch: true },
  { field: 'ReplacementEquipmentAddedAt', header: 'Replacement Equipment Added Date', order: 1010, hide: true, excludeFromGlobalSearch: true },
  // TODO end remove for Hardware version
  // TODO add for Hardware version
  /*
  { field: 'hardwareId', header: 'Hardware Id', order: 1020, hide: true, excludeFromGlobalSearch: true },
  { field: 'hardwareName', header: 'Hardware Name', order: 1030, hide: true, filterMatchMode: 'startsWith' },
  { field: 'hardwareSerial', header: 'Hardware Serial', order: 1040, hide: true, filterMatchMode: 'startsWith', excludeFromGlobalSearch: true },
  { field: 'hardwareStatus', header: 'Hardware Status', order: 1050, hide: true, excludeFromGlobalSearch: true },
  { field: 'hardwareAddedAt', header: 'Hardware Added Date', order: 1060, hide: true, excludeFromGlobalSearch: true },
  { field: 'hardwareSets', header: 'Hardware Sets', order: 1070, hide: true },
   */
  { field: 'notesDate', header: 'Notes Date', order: 1080, hide: true, excludeFromGlobalSearch: true },
  { field: 'keySafesTitle', header: 'keySafes Title', order: 1090, hide: true, filterMatchMode: 'startsWith' },
  { field: 'keySafesStatus', header: 'keySafes Status', order: 1100, hide: true, excludeFromGlobalSearch: true },
  { field: 'keySafesAddedAt', header: 'keySafes Added Date', order: 1110, hide: true, excludeFromGlobalSearch: true },
  // Exclude from global as just searches note content
  { field: 'notesWithDate', header: 'Notes + Dates', order: 1120, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true },
  { field: 'notesUserName', header: 'Notes Username', order: 1130, hide: true, filterMatchMode: 'contains', excludeFromGlobalSearch: true },
  { field: 'statusSetBy', header: 'Status Set By', order: 1140, hide: true, usePColumnFilter: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'statusDate', header: 'Status Date', order: 1150, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'backgroundAutoTestCall', header: 'Background Auto Test Call', order: 1160, hide: true, excludeFromGlobalSearch: true },
  { field: 'freemonths', header: 'Free Months', order: 1170, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'lastBoxActivation', header: 'Box - Last Activation', order: 1180, hide: true, excludeFromGlobalSearch: true },
  { field: 'pendantOneLastActivation', header: 'Pendant 1 - Last Activation', order: 1190, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'pendantTwoLastActivation', header: 'Pendant 2 - Last Activation', order: 1200, hide: true, excludeFromGlobalSearch: true },
  { field: 'lastMainsFail', header: 'Last Mains Fail', order: 1210, hide: true, excludeFromGlobalSearch: true },
  { field: 'mainsFailCount', header: 'Mains Fail Count', order: 1220, hide: true, excludeFromGlobalSearch: true },
  { field: 'lastSmokeAlarmActivation', header: 'Last Smoke Alarm Activation', order: 1230, hide: true, excludeFromGlobalSearch: true },
  { field: 'lastCODetectorActivation', header: 'Last CO Detector Activation', order: 1240, hide: true, excludeFromGlobalSearch: true },
  { field: 'iDetailsInstallationAddress', header: 'Initial Order Installation Address', order: 1250, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceFirstName', header: 'Correspondence First Name', order: 1260, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceLastName', header: 'Correspondence Last Name', order: 1270, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceEmail', header: 'Correspondence Email', order: 1280, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceEmailMarketing', header: 'Correspondence Email Marketing', order: 1282, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceEmailMarketingUpdated', header: 'Correspondence Email Marketing Updated', order: 1284, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceTelephone', header: 'Correspondence Telephone', order: 1290, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceMobile', header: 'Correspondence Mobile', order: 1300, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceMobileMarketing', header: 'Correspondence Mobile Marketing', order: 1302, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceMobileMarketingUpdated', header: 'Correspondence Mobile Marketing Updated', order: 1304, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceAddressOne', header: 'Correspondence Address Line One', order: 1310, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceAddressTwo', header: 'Correspondence Address Line Two', order: 1320, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceCity', header: 'Correspondence City', order: 1330, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondenceCounty', header: 'Correspondence County', order: 1340, hide: true, excludeFromGlobalSearch: true },
  { field: 'correspondencePostcode', header: 'Correspondence Postcode', filterMatchMode: 'contains', order: 1350, hide: true, excludeFromGlobalSearch: true },
  { field: 'lastHighUseDate', header: 'Last Appeared On High Use Report', order: 1360, hide: true, excludeFromGlobalSearch: true },
  { field: 'highUseCount', header: 'Consecutive Months on High Use Report', order: 1370, hide: true, excludeFromGlobalSearch: true },
  { field: 'lastGpsActivation', header: 'Last GPS Activation', order: 1380, hide: true, excludeFromGlobalSearch: true },
  { field: 'discounts', header: 'Discounts', order: 1390, hide: true, excludeFromGlobalSearch: true },
  { field: 'lastRadioTestMissing', header: 'Last Radio Test Missing', order: 1400, hide: true, excludeFromGlobalSearch: true },
  { field: 'lastEpilepsyAlert', header: 'Last Epilepsy Alert', order: 1410, hide: true, excludeFromGlobalSearch: true },
  { field: 'stripeCustomerId', header: 'Stripe Customer Id', order: 1420, hide: true, keepOnHome: true },
  { field: 'stripeSubscriptionId', header: 'Stripe Subscription Id', order: 1430, hide: true, excludeFromGlobalSearch: true },
  { field: 'customerCalledFrom', header: 'Customer Called From', order: 1440, hide: true, excludeFromGlobalSearch: true },
  { field: 'goCardlessAccount', header: 'GoCardless Account', order: 1449, hide: true },
  { field: 'goCardlessId', header: 'GoCardless Customer Id', order: 1450, hide: true, excludeFromGlobalSearch: true },
  { field: 'goCardlessSubId', header: 'GoCardless Subscription Id', order: 1460, hide: true, excludeFromGlobalSearch: true },
  { field: 'goCardlessMandateId', header: 'GoCardless Mandate Id', order: 1461, hide: true },
  { field: 'pslid', header: 'SmartDebit Account (Brand) Id', order: 1470, hide: true, excludeFromGlobalSearch: true },
  { field: 'referencenumber', header: 'SmartDebit BACS Reference', order: 1480, hide: true, excludeFromGlobalSearch: true },
  { field: 'serviceNames', header: 'Service Names', order: 1500, hide: true, excludeFromGlobalSearch: true },
  { field: 'serviceStatuses', header: 'Service Statuses', order: 1510, hide: true, excludeFromGlobalSearch: true },
  { field: 'servicesAddedAt', header: 'Services Added', order: 1520, hide: true, excludeFromGlobalSearch: true },
  { field: 'legalCompany', header: 'Legal Company', order: 1530, hide: true, excludeFromGlobalSearch: true, keepOnHome: true },
  { field: 'orderSource', header: 'Order Source', order: 1540, hide: true, excludeFromGlobalSearch: true, keepOnHome: false },
];

function getHomepageColumns(): Column[] {
  //return orderSpreadsheetCols.filter((col: Column) => col.keepOnHome);
  return orderSpreadsheetCols;
}

export {
  orderSpreadsheetCols,
  getHomepageColumns,
}
