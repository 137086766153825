import {environment} from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CrmNotification} from '../models/notifications/crmNotification.model';
import {Observable} from 'rxjs';
import {MultiRecordResponse} from '../models/responses/multiRecordResponse.model';
import {UserNotification} from '../models/notifications/userNotification.model';
import {SingleRecordResponse} from '../models/responses/singleRecordResponse.model';
import {SimpleResponse} from '../models/responses/simpleResponse.model';
import {MarkNotificationAsReadRequest} from '../models/requests/markNotificationAsReadRequest.model';
import {MarkAllNotificationAsReadRequest} from '../models/requests/markAllNotificationAsReadRequest.model';
import {SendEmailRequest} from '../models/requests/sendEmailRequest.model';
import {Order} from '../models/order.model';
import {renewalTypeDisplayLookup} from '../lookups/renewalTypes';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient
  ) { }
  private API_URL: string = environment.protocol + environment.IPAddress + '/api';

  createNotification(params: CrmNotification): Observable<MultiRecordResponse<UserNotification>> {
    return this.http.post<MultiRecordResponse<UserNotification>>(`${this.API_URL}/notifications/create`, params);
  }

  getOutstandingNotificationsCount(userId: string): Observable<SingleRecordResponse<number>> {
    return this.http.get<SingleRecordResponse<number>>(`${this.API_URL}/notifications/outstanding-notification-count/${userId}`);
  }

  getUserUnreadNotifications(userId: string): Observable<MultiRecordResponse<UserNotification>> {
    return this.http.get<MultiRecordResponse<UserNotification>>(`${this.API_URL}/notifications/un-read/${userId}`);
  }

  getNotificationDetailById(notificationId: string): Observable<SingleRecordResponse<UserNotification>> {
    return this.http.get<SingleRecordResponse<UserNotification>>(`${this.API_URL}/notifications/notification/${notificationId}`);
  }

  markNotificationAsRead(params: MarkNotificationAsReadRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/notifications/mark-as-read`, params);
  }

  markAllNotificationsAsRead(params: MarkAllNotificationAsReadRequest): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(`${this.API_URL}/notifications/mark-all-as-read`, params);
  }

  sendEmail(params: SendEmailRequest): Observable<MultiRecordResponse<string>> {
    return this.http.post<MultiRecordResponse<string>>(`${this.API_URL}/notifications/send-email`, params);
  }

  getArcCancellationEmailParams(emailType: string, order: Order, username: string): SendEmailRequest|undefined {
    const adminRecipients: string[] = JSON.parse(localStorage.getItem('email: ARC Admin Team'));
    const alarmCentreRecipients: string[] = JSON.parse(localStorage.getItem('email: ARC Alarm Centre'));

    let renewalInfo: string = '';
    if (localStorage.getItem('email: Admin')) {
      if (['Order Cancelled'].includes(emailType) &&
          ['directDebit', 'goCardless', 'recurringBilling'].includes(order.renewalInformation.renewalType)) {
        adminRecipients.push(...JSON.parse(localStorage.getItem('email: Admin')));
        renewalInfo = `*** Note customer has renewal type ${renewalTypeDisplayLookup[order.renewalInformation.renewalType]} ***`;
      }
    }

    const customerName: string = `${order.alarmUserDetails.firstName} ${order.alarmUserDetails.lastName}`;
    const tdCode: string = order.alarmUserDetails.tdCode;
    const orderLink: string = `${environment.protocol}${environment.IPAddress}/order/${order._id}`;

    switch (emailType) {
      case 'Customer Cancellation RIP':
        return {
          'recipients': alarmCentreRecipients,
          'subject': `${order.website.title} ${emailType} - ${tdCode} ${customerName}`,
          'plainTextMsg':
            `Hi Team,\nPlease be advised that the following customer has sadly passed away.\n` + 
            `Customer Name: ${customerName}\nCustomer Order (TD): ${tdCode} ${orderLink}\n` +
            `Thanks,\n${username}`,
          'htmlMsg':
            `<p>Hi Team,</p><p>Please be advised that the following customer has sadly passed away.</p>` + 
            `<p>Customer Name: ${customerName}</p><p>Customer Order (TD): <a href="${orderLink}" target="_blank">${tdCode}</a></p>` +
            `<p>Thanks,<br/>${username}</p>`,
        };
      case 'Care Home Customer Cancellation':
        return {
          'recipients': alarmCentreRecipients,
          'subject': `${order.website.title} ${emailType} - ${tdCode} ${customerName}`,
          'plainTextMsg':
            `Hi Team,\nPlease be advised that the following customer is cancelling their service due to moving into a care home.\n` + 
            `Customer Name: ${customerName}\nCustomer Order (TD): ${tdCode} ${orderLink}\n` +
            `Thanks,\n${username}`,
          'htmlMsg':
            `<p>Hi Team,</p><p>Please be advised that the following customer is cancelling their service due to moving into a care home.</p>` + 
            `<p>Customer Name: ${customerName}</p><p>Customer Order (TD): <a href="${orderLink}" target="_blank">${tdCode}</a></p>` +
            `<p>Thanks,<br/>${username}</p>`,
        };
      case 'Order Cancelled':
        return {
          'recipients': adminRecipients,
          'subject': `${order.website.title} ${emailType} - ${tdCode} ${customerName}. Renewal Type: ${order.renewalInformation.renewalType}`,
          'plainTextMsg': 
              `Hi Team,\nPlease be advised that the following order has been changed to Cancelled status.\n` +
              `${renewalInfo? renewalInfo + '\n': ''}` +
              `Customer Order (TD): ${tdCode} ${orderLink}\nThanks,\n${username}`,
          'htmlMsg': 
              `<p>Hi Team,</p>` +
              `<p>Please be advised that the following order has been changed to Cancelled status.</p>` +
              `${renewalInfo? '<p>' + renewalInfo + '</p>': ''}` +
              `<p>Customer Order (TD): <a href='${orderLink}' target='_blank'>${tdCode}</a></p><p>Thanks,<br/>${username}</p>`,
        };
      case 'Order Reactivated': 
        return {
          'recipients': adminRecipients,
          'subject': `${order.website.title} ${emailType} - ${tdCode} ${customerName}`,
          'plainTextMsg': 
              `Hi Team,\nPlease be advised that the following order has been reactivated.\n` + 
              `Customer Order (TD): ${tdCode} ${orderLink}\nThanks,\n${username}`,
          'htmlMsg': 
              `<p>Hi Team,</p>` +
              `<p>Please be advised that the following order has been reactivated.</p>` + 
              `<p>Customer Order (TD): <a href='${orderLink}' target='_blank'>${tdCode}</a></p><p>Thanks,<br/>${username}</p>`,
        };
      default:
        return {
          'recipients': alarmCentreRecipients,
          'subject': `${order.website.title} ${emailType} - ${tdCode} ${customerName}`,
          'plainTextMsg':
            `Hi Team,\nPlease be advised that the following customer is cancelling their service. ${emailType}.\n` + 
            `Customer Name: ${customerName}\nCustomer Order (TD): ${tdCode} ${orderLink}\n` +
            `Thanks,\n${username}`,
          'htmlMsg':
            `<p>Hi Team,</p><p>Please be advised that the following customer is cancelling their service. ${emailType}.</p>` + 
            `<p>Customer Name: ${customerName}</p><p>Customer Order (TD): <a href="${orderLink}" target="_blank">${tdCode}</a></p>` +
            `<p>Thanks,<br/>${username}</p>`,
        };
    }
  }

  getArcContactChangesEmailParams(emailType: string, order: Order, username: string, changes: string[]): SendEmailRequest|undefined {
    const alarmCentreRecipients: string[] = JSON.parse(localStorage.getItem('email: ARC Alarm Centre'));
    const customerName: string = `${order.alarmUserDetails.firstName} ${order.alarmUserDetails.lastName}`;
    const tdCode: string = order.alarmUserDetails.tdCode;
    const orderLink: string = `${environment.protocol}${environment.IPAddress}/order/${order._id}`;
    return {
      'recipients': alarmCentreRecipients,
      'subject': `${order.website.title} ${emailType} - ${tdCode} ${customerName}`,
      'plainTextMsg': 
          `Hi Team,\nPlease be advised that the following customer's or a contact's name and/or contact details have been updated.\n` +
          changes.join('\n') +
          `Customer Order (TD): ${tdCode} ${orderLink}\nThanks,\n${username}`,
      'htmlMsg': 
          `<p>Hi Team,</p>` +
          `<p>Please be advised that the following customer's or a contact's name and/or contact details have been updated.</p>` + 
          changes.join('<br/>') +
          `<p>Customer Order (TD): <a href='${orderLink}' target='_blank'>${tdCode}</a></p><p>Thanks,<br/>${username}</p>`,
    };
  }
}
